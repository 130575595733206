import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const P = () => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
              background: {
                 color: {
                     value: "#252934",
                 },
               },
                fullScreen: false,
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onHover: {
                        enable: true,
                        mode: "grab",
                    },
                      onClick: {
                          enable: true,
                          mode: "push",
                      },
                      resize: true,
                    },
                    modes: {
                      push: {
                        quantity: 4,
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4
                      },
                  },
                },
                particles: {
                  number: {
                    value: 200,
                    density: {
                        enable: true,
                        area: 800,
                    },
                  },
                  color: {
                      value: "#00a1c9",
                  },
                  shape: {
                    type: "circle",
                    stroke: {
                      width: 0,
                      color: "#000000"
                    },
                  },
                  opacity: {
                    value: 0.5,
                    random: false,
                  },
                  links: {
                      color: "#E31B6D",
                      distance: 150,
                      enable: false,
                      opacity: 0.4,
                      width: 1,
                  },
                  move: {
                    directions: "none",
                    enable: true,
                    random: false,
                    speed: 2,
                    straight: false,
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 1200
                    }
                  },
                  size: {
                      // value: { min: 1, max: 5 },
                      value: 3,
                      random: true,
                      anim: {
                        enable: false,
                        speed: 1,
                        opacityMin: 0.1,
                        sync: false
                      }
                  },
                },
                detectRetina: true,
            }}
        />
    );
};

export default P;
