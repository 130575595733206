import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/Home';
import Navbar from './components/navigation/Navbar'
import Footer from './components/navigation/Footer'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navbar />
    <Home />
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
