
import React, { Component } from 'react';
import headshot from '../../assets/images/headshot.jpg'
import speedIcon from '../../assets/icons/icon-speed.svg'
import successPackageIcon from '../../assets/icons/icon-package-success.svg'
import routePackageIcon from '../../assets/icons/icon-route.svg'
import { Element, Link } from 'react-scroll'


export default class About extends Component {
  render() {
    return (
      <Element name="about" className="about-container section-container section-background-secondary">
        <div className="section-header">
          ABOUT
          <div className="section-header-underline"/>
        </div>
        <div className="container about-grid-container">
          <div className="row highlights-row">
            { this.renderHighlights() }
          </div>
          <div className="row skills-row">
            <div className="who-column col-lg-5 col-sm-12">
              <img className="headshot" src={headshot} alt='headshot'/>
              <div className="headshot-title">Who?</div>
              <div className="headshot-description">
                I am a Staff Engineer for <a href="https://checkr.com/">Checkr</a>, a two time CTO and a Techstars (Boulder '14') founder.
                <br/>
                <Link to='contact'>Let's make something special.</Link>
              </div>
            </div>
            { this.renderSkills() }
          </div>
        </div>
      </Element>
    )
  }

  renderSkills = () => {
    return (
      <div className="skills col-lg-7 col-sm-12">
        <div className='row'>
          <div className='skills-title col-xl-3 col-lg-12'>Expert:</div>
          <div className='skill-group col-xl-9 col-lg-12'>
            <div className='skills-item'>Ruby/Rails</div>
            <div className='skills-item'>React</div>
            <div className='skills-item'>Node.js</div>
            <div className='skills-item'>Kafka</div>
            <div className='skills-item'>SQL/NoSQL</div>
          </div>
        </div>
        <div className='row'>
          <div className='skills-title col-xl-3 col-lg-12'>Skilled:</div>
          <div className='skill-group col-xl-9 col-lg-12'>
            <div className='skills-item'>Redis</div>
            <div className='skills-item'>TDD</div>
            <div className='skills-item'>Lambda</div>
            <div className='skills-item'>AWS</div>
            <div className='skills-item'>Kubernetes</div>
          </div>
        </div>
        <div className='row'>
          <div className='skills-title col-xl-3 col-lg-12'>Adept:</div>
          <div className='skill-group col-xl-9 col-lg-12'>
            <div className='skills-item'>Java</div>
            <div className='skills-item'>Vue</div>
          </div>
        </div>
      </div>
    )
  }

  renderHighlights = () => {
    return highlights.map((highlight) => {
      return (
        <div className="highlight col-md-4 col-sm-12" key={ highlight.id }>
          <div className="highlight-bubble">
            <img
              style={ highlight.iconStyles }
              className="highlight-bubble-icon"
              src={highlight.icon}
              alt={`${highlight.title} icon`}
              />
            </div>
            <div className="highlight-title">{highlight.title }</div>
            <div className="highlight-description">{highlight.description }</div>
          </div>
      )
    })
  }
}

const highlights = [
  {
    id: 1,
    title: 'Fast',
    description: 'I ship code quickly so you can deliver values to your users sooner.',
    icon: speedIcon,
    iconStyles: {
      height: '75px',
      marginTop: '45px',
    },
  },
  {
    id: 2,
    title: 'Business Oriented',
    description: 'The goals of the business are first and front for all engineering decisions made.',
    icon: successPackageIcon,
    iconStyles: {
      height: '100px',
      marginTop: '40px',
      marginLeft: '10px',
    },
  },
  {
    id: 3,
    title: 'Scalable',
    description: 'I implement the little things that will matter down the road, but won\'t hold us up today.',
    icon: routePackageIcon,
    iconStyles: {
      height: '100px',
      marginTtop: '30px',
    },
  }
]
