
import React, { Component } from 'react';
import { Element } from 'react-scroll'

export default class Contact extends Component {


  constructor(props) {
    super(props)
    this.state = { error: null, successMessage: null }
  }


  render() {
    const { error, successMessage } = this.state
    const errorComponent = error ? (<div className='contact-form-error'>{ error }</div>) : null
    const successComponent = successMessage ? (<div className='contact-form-success'>{ successMessage }</div>) : null
    return (
      <Element name="contact" className="contact-container section-container section-background-secondary">
        <div className="section-header">
          CONTACT
          <div className="section-header-underline"/>
        </div>
        <div className='contact-form'>
          <p>Have a question or want to work together?</p>
          <input
            className="contact-form-input contact-name"
            data-name='name'
            placeholder="Name"
            onChange={(e) => this.updateInfo(e)}
          />
          <textarea
            className="contact-form-input contact-form-input-textarea contact-message"
            data-name='message'
            placeholder="Message"
            onChange={(e) => this.updateInfo(e)}
          />
          <input
            className="contact-form-input contact-email"
            data-name='email'
            placeholder="Email"
            onChange={(e) => this.updateInfo(e)}
          />
          { errorComponent }
          { successComponent }
          <button className='submit-button' onClick={this.submitContact}>Submit</button>
        </div>
      </Element>
    )
  }

  updateInfo = (e) => {
    const stateUpdate = []
    stateUpdate[e.target.dataset.name] = e.target.value
    console.log(this.state)
    this.setState(stateUpdate)
  }

  submitContact = () => {
    const { name, message, email } = this.state
    if (!name || !message || !email) {
      this.setState({error: 'Please fill out all fields before submitting'})
    } else {
      this.error = undefined;
      let url = 'https://hooks.zapier.com/hooks/catch/14895008/33pq1b8/?';
      url += `name=${name}&email=${email}&message=${message}`;
      fetch(url, {
      }).then(() => {
        this.successMessage = 'Recieved! I will reach out to you soon.';
        this.setState({
          successMessage: 'Recieved! I will reach out to you soon.', error: null
        })
      }).catch(() => {
        this.setState({error: 'Uh Oh! Something went wrong trying to contact me. Make sure your internet connection is working and try again.'})
      });
    }
  }

}
