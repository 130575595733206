import Particles from './Particles'
import Introduction from './Introduction'
import About from './About'
import Resume from './Resume'
import Contact from './Contact'

function Home() {
  return (
    <div id="home">
      <Particles />
      <Introduction />
      <About />
      <Resume />
      <Contact />
    </div>
  )
}

export default Home;
