import { Element } from 'react-scroll'

function Resume() {
  return (
    <Element name="resume" className="section-container section-background-primary resume-container">
      <div className="section-header">
        Check Out My Resume
        <div className="section-header-underline"/>
      </div>
      <div className="resume-section">
        <a
          className='btn btn-large resume-download'
          target="_blank"
          rel="noreferrer"
          href="https://drive.google.com/file/d/1HOCykJwG136XsIkItbhaJVTy0FoWI1vv/edit"
        >
          Download
        </a>
        <iframe className='resume-frame' title='resume' src="https://drive.google.com/file/d/1HOCykJwG136XsIkItbhaJVTy0FoWI1vv/preview" style={{width: '100%', height: '100%'}} frameBorder="0"></iframe>
      </div>
    </Element>
  )
}

const resumeUrl = 'https://drive.google.com/file/d/1HOCykJwG136XsIkItbhaJVTy0FoWI1vv/view'

export default Resume;
