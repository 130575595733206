import { Element, Link } from 'react-scroll'


function Navbar() {
  return (
    <Element name='top' className='nav'>
      <div className='nav-link-container'>
      <Link to="about">About</Link>
      <Link to="resume">Resume</Link>
      <Link to="contact">Contact</Link>
      </div>
    </Element>
  )
}

export default Navbar;
