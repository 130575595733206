function Introduction() {
  return (
    <div id="introduction">
      <div className="overlay">
        <div className='title-text'>
          Hello, I'm <div className='name-highlight'>Lianne Espeland</div>
        </div>
        <div className='title-text'>I'm a full-stack web developer</div>
        <div className='title-subtext'>with <div className='name-highlight'>11+</div> years of experience</div>
      </div>
    </div>
  )
}

export default Introduction;
