import { Link } from 'react-scroll'
import linkedInIcon from '../../assets/icons/icon-linkedin.svg'
import emailIcon from '../../assets/icons/icon-email.svg'
import githubIcon from '../../assets/icons/icon-github.svg'
import upArrowIcon from '../../assets/icons/icon-up-arrows.svg'

function Footer() {
  return (
    <div id='footer'>
      <Link to="top" className="scroll-top-button">
        <img src={ upArrowIcon } alt='upwards arrow'/>
      </Link>
      <div className="social-icon-group">
        <a href="https://www.linkedin.com/in/lianne-e-19189956/" target="_blank"  rel="noreferrer">
          <img className="social-icon-item" src={ linkedInIcon } alt='linkedIn logo'/>
        </a>
        <a href="mailto:lianne.espeland@gmail.com">
          <img className="social-icon-item" src={ emailIcon } alt='email logo'/>
        </a>
      </div>
    </div>
  )
}

export default Footer;
